import React from 'react';
import firebase from '../../config/fbConfig';
import { ToastContainer, toast } from 'react-toastify';

function GalUpload() {
    const [fileUrl, setFileUrl] = React.useState(null);

    const onFileChange = async (e) => {
      const file = e.target.files[0];
      const storageRef = firebase.storage().ref(`gallery/`);
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      setFileUrl(await fileRef.getDownloadURL());
    };

    const onSubmit = async (e) => {
      e.preventDefault();
      if (!fileUrl) {
        return;
      }
      await firebase.firestore().collection('gallery').add({
        image: fileUrl
      }).then(() => {
        toast.success('Image Added successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    };

    return (
      <>
      <ToastContainer />
      <form onSubmit={onSubmit}>
        <input type="file" name="image" id="image" onChange={onFileChange} />
        <button className="btn btn-purple">Submit</button>
      </form>
      </>
    );
  }

  export default GalUpload;