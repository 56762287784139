import React, { Component, Fragment } from 'react';
import firebase from '../../config/fbConfig';
import {Link} from 'react-router-dom';
import CKEditor from 'ckeditor4-react';
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBBreadcrumb, MDBBreadcrumbItem, MDBInput } from "mdbreact";
import {connect} from 'react-redux';
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

class EditPage extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection('pages');
        this.state = {
            key: '',
            name: '',
            content: ''
        };
    }

    componentDidMount() {
        const ref = firebase.firestore().collection('pages').doc(this.props.match.params.id);
        ref.get().then((doc) => {
          if (doc.exists) {
            const page = doc.data();
            this.setState({
              key: doc.id,
              name: page.name,
              content: page.content
            });
          } else {
            console.log("No such document!");
          }
        });
      }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { name, content} = this.state;

        const updateRef = firebase.firestore().collection('pages').doc(this.state.key);
        updateRef.set({
          name,
          content
        }).then((docRef) => {
          this.setState({
            name: name,
            content: content
          });
          toast.success('Content Updated successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    }

    render() {
    const {auth} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
        return (
            <Fragment>
                <AdminNav />
                <MDBContainer className="mt-5 pt-5">
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to="/AdminPages">Pages</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Edit Page</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <h2>Edit Page</h2>
                    <MDBRow>
                        <MDBCol size="8">
                        <ToastContainer />
                            <form>
                                <MDBInput id="name" label="Page Name" value={this.state.name} name="name" onChange={this.handleChange} />
                                <CKEditor id="content"
                                    onChange={({name, editor}) => {
                                        const data = editor.getData();
                                        this.setState({content: data});
                                    }}
                                    name="content" value={this.state.content} data={this.state.content} 
                                   
                                    />
                                <div className="text-center mt-4">
                                <MDBBtn color="unique" type="submit" onClick={this.handleSubmit}>
                                    Save
                                </MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </Fragment>
         );
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

export default connect(mapStateToProps)(EditPage);