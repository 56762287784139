import React, { Component, Fragment } from 'react';
import { MDBRow, MDBDataTable, MDBCol, MDBIcon, MDBContainer} from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {Link} from 'react-router-dom';
import firebase from '../../config/fbConfig';


class List extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection('pages');
        this.state = { pages: [] }
    }
    onCollectionUpdate = (querySnapshot) => {
      const pages = [];
      querySnapshot.forEach((doc) => {
        const { name, content} = doc.data();
        pages.push({
          key: doc.id,
          doc, // DocumentSnapshot
          name,
          content
        });
      });
      this.setState({
        pages
     });
    }

    componentDidMount() {
      this.ref.onSnapshot(this.onCollectionUpdate);
    }

    render() {
        const pages = this.state.pages;
        const data = {
            columns: [
              {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
              },
              {
                label: "Content",
                field: "content",
                sort: "asc",
                width: 150,
              },
              {
              label: "Update",
              field: "update",
              sort: "asc",
              width: 150,
              }

            ],
            rows : pages.map(page => {
                return {
                     name: page.name,
                    content: page.content,
                    update: (
                        <Link to={`/EditPage/${page.key}`}>
                          <MDBIcon icon="user-edit" size="2x" className="d-flex justify-content-center blue-text" />
                        </Link>
                      )

                }
            })
          };

        return (
        <Fragment>
            <AdminNav />
            <MDBContainer className="mt-5 pt-5">
            <h2>Content Pages</h2>
                <MDBRow className="mt-5 mb-5">
                    <MDBCol size="12">
                        <MDBDataTable striped bordered small data={data} exportToCSV />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Fragment>  );
    }
}

export default List;