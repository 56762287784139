import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import {passReset} from '../../store/actions/passActions';
import {Redirect} from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBAlert
} from "mdbreact";
import Navbar from "../layout/Navbar";

class PassReset extends Component {
  constructor(props) {
    super(props);
    this.state = {email: ''};
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id] : e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.passReset(this.state);
  }

  render() {
    const {authError, auth} = this.props;
    if(auth.uid) return  <Redirect to='/Dashboard' />
    return (
      <Fragment>
        <Navbar />
        <MDBContainer>
          <MDBRow className="mt-5 mb-5">
            <MDBCol md="6" className="offset-md-3">
              <MDBCard>
                <MDBCardBody>
                  <MDBCardHeader className="form-header purple-gradient rounded">
                    <h3 className="my-3">
                      <MDBIcon icon="key" /> Password Reset:
                    </h3>
                  </MDBCardHeader>
                  <form onSubmit={this.handleSubmit}>
                    <div className="grey-text">
                      <MDBInput
                        label="Type your email"
                        icon="envelope"
                        id="email"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="text-center mt-4">
                      <MDBBtn color="light-blue" className="mb-3" type="submit">
                        Email Password Reset
                      </MDBBtn>
                    </div>
                      {authError ? <MDBAlert color="danger">{authError}</MDBAlert> : null }
                  </form>

                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    passReset: (creds) => dispatch(passReset(creds))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(PassReset);
