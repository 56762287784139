import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import {  MDBRow, MDBCol, MDBCard, MDBAvatar, MDBCardBody, MDBContainer, MDBFileInput, MDBCardUp, MDBInput, MDBBtn } from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import ReactHTMLTableToExcel from "react-html-table-to-excel";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {

  };
  }

  render() {
    const {auth} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
    return (
      <Fragment>
        <AdminNav />
        <MDBContainer className="mt-5 pt-5">
          <MDBRow className="mt-5 mb-5">
            <h1>Table</h1>
            <MDBCol size="6">
                <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"
                />
            </MDBCol>
            <MDBCol size="6">
            <table id="table-to-xls">

<thead>

    <tr>


        <th class="av_leadsource header">Leadsouce</th>


        <th class="national_general__ngah_ header">National General (NGAH)</th>


        <th class="florida_blue___florida_agents_only_ header">Florida Blue - FLORIDA AGENTS ONLY!</th>


        <th class="team_corp header">Team Corp</th>


        <th class="vsp header">VSP</th>


        <th class="united_health_one__golden_rule_ header">United Health One (Golden Rule)</th>


        <th class="philadelphia_american header">Philadelphia American</th>


        <th class="gac__general_agent_center_ header">GAC (General Agent Center)</th>


        <th class="health_matching_account_services__hma_ header">Health Matching Account Services (HMA)</th>


        <th class="oscar header">Oscar</th>


        <th class="oneshare_health header">OneShare Health</th>


        <th class="molina_ header">Molina </th>


        <th class="hcsc__bc_bs_of_il__nm__ok__tx_ header">HCSC (BC/BS of IL, NM, OK, TX)</th>


        <th class="pivot_health header">Pivot Health</th>


        <th class="national_general__tic_ header">National General (TIC)</th>


        <th class="health_matching_account_services header">Health Matching Account Services</th>


        <th class="truscript header">TruScript</th>


        <th class="occidental_life header">Occidental Life</th>


        <th class="ambetter header">Ambetter</th>


        <th class="family_life__florida_only_ header">Family Life (Florida Only)</th>


        <th class="national_general__nhic_ header">National General (NHIC)</th>


        <th class="national_brokerage header">National Brokerage</th>


        <th class="mutual_of_omaha header">Mutual of Omaha</th>


        <th class="medico header">Medico</th>


        <th class="antex___slaico header">ANTEX / SLAICO</th>


        <th class="brokers_alliance header">Brokers Alliance</th>


        <th class="manhattan_life____flic_in_fl_ header">Manhattan Life - (FLIC in FL)</th>


        <th class="apex_management_group_mec header">Apex Management Group MEC</th>


        <th class="united_american header">United American</th>


        <th class="uhc_medicare header">UHC Medicare</th>


        <th class="assurity_life header">Assurity Life</th>


        <th class="cigna header">Cigna</th>


        <th class="pet_health_matching_account__phma_ header">Pet Health Matching Account (PHMA)</th>


        <th class="national_general__level_funded_small_group_ header">National General (Level-Funded Small Group)
        </th>


        <th class="humana_senior header">Humana Senior</th>


        <th class="optimum_ma header">Optimum MA</th>


        <th class="ameritas__formerly_security_life_ header">Ameritas (formerly Security Life)</th>


        <th class="medi_share_ header">Medi-Share </th>


        <th class="bc_bs_of_michigan header">BC/BS of Michigan</th>


        <th class="caresource header">CareSource</th>


        <th class="ihc__independence_holding_company_ header">IHC (Independence Holding Company)</th>


        <th class="hii__health_insurance_innovations_ header">HII (Health Insurance Innovations)</th>


        <th class="gtl__guarantee_trust_life_ header">GTL (Guarantee Trust Life)</th>


        <th class="aetna_senior header">Aetna Senior</th>


        <th class="careington header">Careington</th>


        <th class="aliera_healthcare header">Aliera Healthcare</th>


        <th class="medical_mutual_of_ohio header">Medical Mutual of Ohio</th>


        <th class="national_guardian_life__ngl_ header">National Guardian Life (NGL)</th>


        <th class="av_total header headerSortUp">Total</th>


    </tr>

</thead>


<tbody>

















































































































































































































    <tr class="odd">




        <td class="av_leadsource">Personal Referral</td>








        <td class="national_general__ngah_">$ 217,058.18</td>








        <td class="florida_blue___florida_agents_only_">$ 821,252.28</td>








        <td class="team_corp">$ 29,447.88</td>








        <td class="vsp">$ 7,165.44</td>








        <td class="united_health_one__golden_rule_">$ 434,775.64</td>








        <td class="philadelphia_american">$ 3,986,719.92</td>








        <td class="gac__general_agent_center_">$ 83,619.51</td>








        <td class="health_matching_account_services__hma_">$ 144,264.00</td>








        <td class="oscar">$ 35,543.16</td>








        <td class="oneshare_health">$ 255,552.00</td>








        <td class="molina_">$ 17,382.48</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 29,096.76</td>








        <td class="pivot_health">$ 38,734.26</td>








        <td class="national_general__tic_">$ 5,919.48</td>








        <td class="health_matching_account_services">$ 38,778.00</td>








        <td class="truscript">$ 1,675.80</td>








        <td class="occidental_life">$ 30,202.44</td>








        <td class="ambetter">$ 20,957.52</td>








        <td class="family_life__florida_only_">$ 37,296.00</td>








        <td class="national_general__nhic_">$ 68,643.00</td>








        <td class="national_brokerage">$ 111,323.28</td>








        <td class="mutual_of_omaha">$ 102,851.28</td>








        <td class="medico">$ 1,057.68</td>








        <td class="antex___slaico">$ 45,829.56</td>








        <td class="brokers_alliance">$ 21,696.72</td>








        <td class="manhattan_life____flic_in_fl_">$ 164,814.36</td>








        <td class="apex_management_group_mec">$ 4,497.42</td>








        <td class="united_american">$ 15,360.00</td>








        <td class="uhc_medicare">$ 15,692.88</td>








        <td class="assurity_life">$ 1,625.04</td>








        <td class="cigna">$ 12,696.72</td>








        <td class="pet_health_matching_account__phma_">$ 5,736.00</td>








        <td class="national_general__level_funded_small_group_">$ 185,979.84</td>








        <td class="humana_senior">$ 1,670.40</td>








        <td class="optimum_ma">$ 0.00</td>








        <td class="ameritas__formerly_security_life_">$ 1,605.12</td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan">$ 36,558.12</td>








        <td class="caresource">$ 2,920.68</td>








        <td class="ihc__independence_holding_company_">$ 4,188.72</td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington">$ 965.76</td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio">$ 9,492.00</td>








        <td class="national_guardian_life__ngl_">$ 7,209.48</td>








        <td class="av_total">$ 7,057,854.81</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">B Leads/USABG</td>








        <td class="national_general__ngah_">$ 50,637.86</td>








        <td class="florida_blue___florida_agents_only_">$ 412,448.24</td>








        <td class="team_corp">$ 5,580.00</td>








        <td class="vsp">$ 2,991.72</td>








        <td class="united_health_one__golden_rule_">$ 221,983.29</td>








        <td class="philadelphia_american">$ 1,560,596.76</td>








        <td class="gac__general_agent_center_">$ 16,369.32</td>








        <td class="health_matching_account_services__hma_">$ 13,164.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 424,663.08</td>








        <td class="molina_">$ 44,400.00</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 11,978.04</td>








        <td class="pivot_health">$ 48,820.86</td>








        <td class="national_general__tic_">$ 33,876.00</td>








        <td class="health_matching_account_services">$ 11,826.00</td>








        <td class="truscript">$ 239.88</td>








        <td class="occidental_life">$ 5,524.80</td>








        <td class="ambetter">$ 7,586.28</td>








        <td class="family_life__florida_only_">$ 2,321.28</td>








        <td class="national_general__nhic_">$ 26,556.24</td>








        <td class="national_brokerage">$ 1,744.08</td>








        <td class="mutual_of_omaha">$ 15,604.80</td>








        <td class="medico">$ 1,212.00</td>








        <td class="antex___slaico">$ 359.40</td>








        <td class="brokers_alliance">$ 10,519.20</td>








        <td class="manhattan_life____flic_in_fl_">$ 888,566.16</td>








        <td class="apex_management_group_mec">$ 12,016.20</td>








        <td class="united_american">$ 768.00</td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 3,832,353.49</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Current Client</td>








        <td class="national_general__ngah_">$ 465,376.96</td>








        <td class="florida_blue___florida_agents_only_">$ 583,959.76</td>








        <td class="team_corp">$ 6,324.00</td>








        <td class="vsp">$ 3,877.92</td>








        <td class="united_health_one__golden_rule_">$ 378,781.04</td>








        <td class="philadelphia_american">$ 1,157,745.66</td>








        <td class="gac__general_agent_center_">$ 36,541.68</td>








        <td class="health_matching_account_services__hma_">$ 112,973.16</td>








        <td class="oscar">$ 17,241.48</td>








        <td class="oneshare_health">$ 229,176.24</td>








        <td class="molina_">$ 587.16</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 13,968.00</td>








        <td class="pivot_health">$ 81,013.56</td>








        <td class="national_general__tic_">$ 11,305.32</td>








        <td class="health_matching_account_services">$ 42,787.32</td>








        <td class="truscript">$ 957.60</td>








        <td class="occidental_life">$ 29,026.20</td>








        <td class="ambetter">$ 19,443.00</td>








        <td class="family_life__florida_only_">$ 6,790.68</td>








        <td class="national_general__nhic_">$ 57,775.32</td>








        <td class="national_brokerage">$ 3,446.04</td>








        <td class="mutual_of_omaha">$ 74,019.60</td>








        <td class="medico">$ 1,560.00</td>








        <td class="antex___slaico">$ 12,576.12</td>








        <td class="brokers_alliance">$ 13,729.20</td>








        <td class="manhattan_life____flic_in_fl_">$ 199,469.52</td>








        <td class="apex_management_group_mec">$ 18,093.36</td>








        <td class="united_american">$ 43,344.00</td>








        <td class="uhc_medicare">$ 6,164.28</td>








        <td class="assurity_life"></td>








        <td class="cigna">$ 41,912.64</td>








        <td class="pet_health_matching_account__phma_">$ 960.00</td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior">$ 158.40</td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_">$ 6,700.56</td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource">$ 2,288.04</td>








        <td class="ihc__independence_holding_company_">$ 6,056.04</td>








        <td class="hii__health_insurance_innovations_">$ 172.72</td>








        <td class="gtl__guarantee_trust_life_">$ 711.00</td>








        <td class="aetna_senior">$ 17,202.48</td>








        <td class="careington">$ 1,121.40</td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 3,705,337.46</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Telephone Marketing Services Inc</td>








        <td class="national_general__ngah_">$ 106,068.24</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp">$ 4,308.00</td>








        <td class="vsp">$ 1,902.24</td>








        <td class="united_health_one__golden_rule_">$ 50,431.20</td>








        <td class="philadelphia_american">$ 2,041,150.92</td>








        <td class="gac__general_agent_center_">$ 50,014.20</td>








        <td class="health_matching_account_services__hma_">$ 37,483.08</td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 124,471.20</td>








        <td class="molina_">$ 13,202.16</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health">$ 5,977.56</td>








        <td class="national_general__tic_">$ 277.44</td>








        <td class="health_matching_account_services">$ 18,456.00</td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 3,738.84</td>








        <td class="national_brokerage">$ 430.32</td>








        <td class="mutual_of_omaha">$ 9,578.28</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 4,407.60</td>








        <td class="manhattan_life____flic_in_fl_">$ 165,664.56</td>








        <td class="apex_management_group_mec">$ 1,616.40</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna">$ 1,685.52</td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource">$ 655.56</td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington">$ 203.40</td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,641,722.72</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Competitor Referral</td>








        <td class="national_general__ngah_">$ 30,373.58</td>








        <td class="florida_blue___florida_agents_only_">$ 982,369.02</td>








        <td class="team_corp">$ 1,716.00</td>








        <td class="vsp">$ 447.00</td>








        <td class="united_health_one__golden_rule_">$ 63,831.99</td>








        <td class="philadelphia_american">$ 309,105.36</td>








        <td class="gac__general_agent_center_">$ 2,727.48</td>








        <td class="health_matching_account_services__hma_">$ 7,920.00</td>








        <td class="oscar">$ 32,204.94</td>








        <td class="oneshare_health">$ 17,662.92</td>








        <td class="molina_">$ 35,220.36</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 33,204.00</td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 274,305.46</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 632.28</td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 2,683.20</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 11,350.68</td>








        <td class="apex_management_group_mec">$ 1,808.52</td>








        <td class="united_american"></td>








        <td class="uhc_medicare">$ 661.56</td>








        <td class="assurity_life"></td>








        <td class="cigna">$ 18,446.52</td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan">$ 8,031.12</td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,834,701.99</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">BNI Referral</td>








        <td class="national_general__ngah_">$ 46,887.00</td>








        <td class="florida_blue___florida_agents_only_">$ 236,757.12</td>








        <td class="team_corp">$ 1,776.00</td>








        <td class="vsp">$ 4,725.60</td>








        <td class="united_health_one__golden_rule_">$ 65,309.39</td>








        <td class="philadelphia_american">$ 622,730.04</td>








        <td class="gac__general_agent_center_">$ 23,273.76</td>








        <td class="health_matching_account_services__hma_">$ 35,975.40</td>








        <td class="oscar">$ 10,181.04</td>








        <td class="oneshare_health">$ 33,106.08</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health">$ 4,549.29</td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript">$ 478.80</td>








        <td class="occidental_life">$ 1,799.52</td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 1,353.48</td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 18,693.48</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 3,478.44</td>








        <td class="manhattan_life____flic_in_fl_">$ 3,697.56</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american">$ 7,224.00</td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life">$ 117.12</td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_">$ 480.00</td>








        <td class="national_general__level_funded_small_group_">$ 25,522.32</td>








        <td class="humana_senior">$ 0.00</td>








        <td class="optimum_ma">$ 0.00</td>








        <td class="ameritas__formerly_security_life_">$ 852.24</td>








        <td class="medi_share_">$ 3,036.00</td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,152,003.68</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Prospect America</td>








        <td class="national_general__ngah_">$ 24,198.18</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp">$ 1,716.00</td>








        <td class="vsp">$ 1,352.04</td>








        <td class="united_health_one__golden_rule_">$ 18,991.92</td>








        <td class="philadelphia_american">$ 849,354.00</td>








        <td class="gac__general_agent_center_">$ 12,109.80</td>








        <td class="health_matching_account_services__hma_">$ 15,324.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 11,325.96</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health">$ 2,159.10</td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 1,740.00</td>








        <td class="truscript">$ 239.40</td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 315.84</td>








        <td class="national_brokerage">$ 5,735.76</td>








        <td class="mutual_of_omaha">$ 1,120.68</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 14,456.04</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington">$ 204.00</td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 960,342.72</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Blast Leads</td>








        <td class="national_general__ngah_">$ 26,627.37</td>








        <td class="florida_blue___florida_agents_only_">$ 165,894.00</td>








        <td class="team_corp">$ 4,092.00</td>








        <td class="vsp">$ 3,499.08</td>








        <td class="united_health_one__golden_rule_">$ 66,677.60</td>








        <td class="philadelphia_american">$ 284,330.04</td>








        <td class="gac__general_agent_center_">$ 2,098.20</td>








        <td class="health_matching_account_services__hma_">$ 6,576.00</td>








        <td class="oscar">$ 30,763.44</td>








        <td class="oneshare_health">$ 19,691.28</td>








        <td class="molina_">$ 22,970.76</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 40,598.76</td>








        <td class="pivot_health">$ 855.06</td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 1,860.00</td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 84,563.88</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 9,449.16</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 4,980.84</td>








        <td class="apex_management_group_mec">$ 10,392.84</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life">$ 1,674.60</td>








        <td class="cigna">$ 12,485.76</td>








        <td class="pet_health_matching_account__phma_">$ 480.00</td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 800,560.67</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">USABG Referral</td>








        <td class="national_general__ngah_">$ 18,505.80</td>








        <td class="florida_blue___florida_agents_only_">$ 224,607.48</td>








        <td class="team_corp">$ 1,428.00</td>








        <td class="vsp">$ 916.92</td>








        <td class="united_health_one__golden_rule_">$ 20,106.48</td>








        <td class="philadelphia_american">$ 322,527.72</td>








        <td class="gac__general_agent_center_">$ 2,158.20</td>








        <td class="health_matching_account_services__hma_">$ 7,374.00</td>








        <td class="oscar">$ 6,718.44</td>








        <td class="oneshare_health">$ 26,714.16</td>








        <td class="molina_">$ 26,175.12</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 11,271.00</td>








        <td class="pivot_health">$ 4,180.80</td>








        <td class="national_general__tic_">$ 6,308.28</td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 28,975.92</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 1,480.56</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 13,476.96</td>








        <td class="apex_management_group_mec">$ 1,808.52</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life">$ 2,253.48</td>








        <td class="cigna">$ 12,057.60</td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan">$ 24,493.44</td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_">$ 2,145.12</td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 765,684.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Existing client</td>








        <td class="national_general__ngah_">$ 77,547.96</td>








        <td class="florida_blue___florida_agents_only_">$ 204,347.52</td>








        <td class="team_corp">$ 888.00</td>








        <td class="vsp">$ 134.40</td>








        <td class="united_health_one__golden_rule_">$ 59,941.12</td>








        <td class="philadelphia_american">$ 66,061.08</td>








        <td class="gac__general_agent_center_">$ 1,438.80</td>








        <td class="health_matching_account_services__hma_">$ 6,408.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 4,835.64</td>








        <td class="molina_">$ 3,043.80</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_">$ 632.28</td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 34,954.08</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec">$ 3,617.04</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 463,849.72</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">LinkdeIn</td>








        <td class="national_general__ngah_">$ 3,599.40</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp">$ 4,884.00</td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 270,456.36</td>








        <td class="gac__general_agent_center_">$ 1,438.80</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 280,378.56</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Blast Lead</td>








        <td class="national_general__ngah_">$ 3,796.92</td>








        <td class="florida_blue___florida_agents_only_">$ 44,516.88</td>








        <td class="team_corp"></td>








        <td class="vsp">$ 644.16</td>








        <td class="united_health_one__golden_rule_">$ 4,676.16</td>








        <td class="philadelphia_american">$ 31,780.20</td>








        <td class="gac__general_agent_center_">$ 2,625.84</td>








        <td class="health_matching_account_services__hma_">$ 13,800.00</td>








        <td class="oscar">$ 0.00</td>








        <td class="oneshare_health">$ 15,497.28</td>








        <td class="molina_">$ 41,019.70</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 7,660.56</td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 1,680.00</td>








        <td class="truscript">$ 239.40</td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 54,564.83</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 4,759.56</td>








        <td class="medico">$ 409.08</td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 7,125.00</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare">$ 0.00</td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 234,795.57</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Cold Call</td>








        <td class="national_general__ngah_">$ 11,005.08</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp">$ 888.00</td>








        <td class="vsp">$ 251.88</td>








        <td class="united_health_one__golden_rule_">$ 26,144.40</td>








        <td class="philadelphia_american">$ 140,068.68</td>








        <td class="gac__general_agent_center_">$ 2,412.00</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar">$ 6,381.24</td>








        <td class="oneshare_health">$ 10,253.40</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health">$ 12,318.60</td>








        <td class="national_general__tic_">$ 612.00</td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life">$ 2,432.16</td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 560.64</td>








        <td class="national_brokerage">$ 1,115.16</td>








        <td class="mutual_of_omaha">$ 2,178.96</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 597.96</td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna">$ 10,769.88</td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_">$ 460.56</td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 228,450.60</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Walkin</td>








        <td class="national_general__ngah_">$ 3,161.40</td>








        <td class="florida_blue___florida_agents_only_">$ 89,185.92</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 24,252.84</td>








        <td class="philadelphia_american">$ 27,317.16</td>








        <td class="gac__general_agent_center_">$ 479.40</td>








        <td class="health_matching_account_services__hma_">$ 480.00</td>








        <td class="oscar">$ 11,608.80</td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life">$ 1,323.00</td>








        <td class="ambetter">$ 11,906.40</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma">$ 0.00</td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 169,714.92</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Social Media</td>








        <td class="national_general__ngah_">$ 2,796.88</td>








        <td class="florida_blue___florida_agents_only_">$ 31,445.16</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 1,375.68</td>








        <td class="philadelphia_american">$ 99,152.04</td>








        <td class="gac__general_agent_center_">$ 1,558.80</td>








        <td class="health_matching_account_services__hma_">$ 1,860.00</td>








        <td class="oscar">$ 18,924.84</td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life">$ 3,109.32</td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare">$ 5,475.72</td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 165,698.44</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Client Referral </td>








        <td class="national_general__ngah_">$ 1,264.56</td>








        <td class="florida_blue___florida_agents_only_">$ 88,922.04</td>








        <td class="team_corp"></td>








        <td class="vsp">$ 268.80</td>








        <td class="united_health_one__golden_rule_">$ 7,145.52</td>








        <td class="philadelphia_american">$ 21,623.04</td>








        <td class="gac__general_agent_center_">$ 779.40</td>








        <td class="health_matching_account_services__hma_">$ 2,340.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 34,450.80</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 156,794.16</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">agent referral</td>








        <td class="national_general__ngah_">$ 1,264.56</td>








        <td class="florida_blue___florida_agents_only_">$ 89,704.44</td>








        <td class="team_corp"></td>








        <td class="vsp">$ 347.76</td>








        <td class="united_health_one__golden_rule_">$ 976.44</td>








        <td class="philadelphia_american">$ 12,263.76</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_">$ 3,012.00</td>








        <td class="oscar">$ 8,688.36</td>








        <td class="oneshare_health">$ 8,615.04</td>








        <td class="molina_">$ 24,336.96</td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 149,209.32</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Networking Group</td>








        <td class="national_general__ngah_">$ 3,001.08</td>








        <td class="florida_blue___florida_agents_only_">$ 17,805.96</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 104,119.92</td>








        <td class="gac__general_agent_center_">$ 1,048.68</td>








        <td class="health_matching_account_services__hma_">$ 6,480.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 10,940.64</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 143,396.28</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">personal</td>








        <td class="national_general__ngah_">$ 12,957.60</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp">$ 240.00</td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 57,773.28</td>








        <td class="philadelphia_american">$ 30,420.84</td>








        <td class="gac__general_agent_center_">$ 647.40</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 13,146.60</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life">$ 767.64</td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_">$ 2,258.40</td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_">$ 4,461.24</td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 122,673.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Chamber of Commerce</td>








        <td class="national_general__ngah_">$ 1,264.56</td>








        <td class="florida_blue___florida_agents_only_">$ 41,174.52</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 6,197.28</td>








        <td class="philadelphia_american">$ 43,055.04</td>








        <td class="gac__general_agent_center_">$ 539.40</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 92,230.80</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Help On Demand</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 66,209.16</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 4,311.60</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 9,803.64</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 80,324.40</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Home Office Referral</td>








        <td class="national_general__ngah_">$ 23,291.64</td>








        <td class="florida_blue___florida_agents_only_">$ 15,418.32</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 33,223.80</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 473.64</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare">$ 1,188.00</td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 73,595.40</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Personal Network</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 73,265.88</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 73,265.88</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Pam's Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 65,506.32</td>








        <td class="gac__general_agent_center_">$ 2,710.80</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 68,217.12</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">All Web Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 32,652.00</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 9,240.00</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 1,288.44</td>








        <td class="pivot_health">$ 1,402.62</td>








        <td class="national_general__tic_">$ 1,200.00</td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 45,783.06</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">TeleConnect Live Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 454.20</td>








        <td class="philadelphia_american">$ 36,796.80</td>








        <td class="gac__general_agent_center_">$ 2,098.20</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 768.96</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 40,118.16</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">personal b leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 36,875.40</td>








        <td class="gac__general_agent_center_">$ 2,218.20</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 39,093.60</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Self generated not from any of these lead sources</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 5,836.20</td>








        <td class="philadelphia_american">$ 32,264.16</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 38,100.36</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">telephone marketing services</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 454.80</td>








        <td class="philadelphia_american">$ 37,368.84</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 37,823.64</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">"C" Leads</td>








        <td class="national_general__ngah_">$ 2,498.76</td>








        <td class="florida_blue___florida_agents_only_">$ 7,137.36</td>








        <td class="team_corp">$ 1,776.00</td>








        <td class="vsp">$ 511.92</td>








        <td class="united_health_one__golden_rule_">$ 6,353.52</td>








        <td class="philadelphia_american">$ 12,854.28</td>








        <td class="gac__general_agent_center_">$ 539.40</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 31,671.24</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Client of former downline</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 11,136.00</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter">$ 17,043.84</td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 28,179.84</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Call-In</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 22,808.40</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 705.84</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna">$ 2,604.42</td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 26,118.66</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Realtorcom</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 2,984.04</td>








        <td class="philadelphia_american">$ 16,820.64</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_">$ 3,636.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 1,010.40</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 24,451.08</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">curent Client</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 22,699.80</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 22,699.80</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">NextGenLeads</td>








        <td class="national_general__ngah_">$ 435.60</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 18,876.36</td>








        <td class="gac__general_agent_center_">$ 959.40</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 339.36</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 20,610.72</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Existing Customer</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 14,971.92</td>








        <td class="gac__general_agent_center_">$ 1,912.68</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life">$ 2,898.72</td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 19,783.32</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">FaceBook</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp">$ 347.76</td>








        <td class="united_health_one__golden_rule_">$ 249.60</td>








        <td class="philadelphia_american">$ 15,278.52</td>








        <td class="gac__general_agent_center_">$ 3,214.08</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 19,089.96</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">VIMO</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_">$ 1,740.00</td>








        <td class="oscar">$ 14,476.32</td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 16,216.32</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Ann lead</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 11,615.16</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 3,072.00</td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 14,687.16</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Next Door</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 13,569.12</td>








        <td class="gac__general_agent_center_">$ 539.40</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 14,108.52</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Producer</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 11,315.91</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health">$ 1,633.56</td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 12,949.47</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Text Lead</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 6,450.00</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 249.60</td>








        <td class="philadelphia_american">$ 6,084.00</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 12,783.60</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Blast</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 9,607.80</td>








        <td class="gac__general_agent_center_">$ 958.80</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 631.44</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 11,198.04</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">My USABG website</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 5,445.12</td>








        <td class="team_corp"></td>








        <td class="vsp">$ 134.40</td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health">$ 4,939.68</td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 10,519.20</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">ASAP</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_">$ 9,600.00</td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 321.00</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 9,921.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Medicare Referral</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 4,052.30</td>








        <td class="philadelphia_american">$ 4,188.12</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico">$ 1,113.36</td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 9,353.78</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">referral</td>








        <td class="national_general__ngah_">$ 2,131.80</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 582.84</td>








        <td class="philadelphia_american">$ 5,362.68</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 458.76</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 342.36</td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 8,878.44</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Lead Hustler</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 7,457.04</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 7,457.04</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Producer Pipeline</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp">$ 151.32</td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 3,465.12</td>








        <td class="gac__general_agent_center_">$ 389.28</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 480.00</td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 519.60</td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec">$ 1,704.00</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 6,709.32</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Direct Mail</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_">$ 778.56</td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services">$ 3,012.00</td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 858.12</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 4,648.68</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Me</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 3,964.44</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_">$ 352.08</td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 4,316.52</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Leadco</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 3,601.80</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 3,601.80</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Real Estate Investment Group</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 3,343.55</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 3,343.55</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Acquaintance</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 2,985.60</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,985.60</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Datalot U65 Live Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 124.80</td>








        <td class="philadelphia_american">$ 2,714.52</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,839.32</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Parasol Leads Co. </td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 1,833.00</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance">$ 926.28</td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,759.28</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">BOOK OF BUSINESS</td>








        <td class="national_general__ngah_">$ 1,190.52</td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_">$ 1,488.00</td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,678.52</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Personal Ref</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 2,000.76</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 2,000.76</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Fronter Leads (Dream Team only)</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 1,985.16</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,985.16</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">CARRIER LEAD</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 279.60</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 943.68</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,223.28</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Life Insurance Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha">$ 1,218.00</td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,218.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Exclusive Super Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage">$ 1,092.36</td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 1,092.36</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">FLYER</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_">$ 969.48</td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 969.48</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">United Quote</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 849.48</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 849.48</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Countryside HOA</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american">$ 672.00</td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 672.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Precise</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american">$ 315.60</td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 315.60</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Net Quote</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_">$ 300.00</td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 300.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Benepath</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_">$ 216.00</td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 216.00</td>






    </tr>
    <tr class="odd">




        <td class="av_leadsource">Leads Are Free</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec">$ 204.00</td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior"></td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 204.00</td>






    </tr>
    <tr class="even">




        <td class="av_leadsource">Exclusive Health Insurance Leads</td>








        <td class="national_general__ngah_"></td>








        <td class="florida_blue___florida_agents_only_"></td>








        <td class="team_corp"></td>








        <td class="vsp"></td>








        <td class="united_health_one__golden_rule_"></td>








        <td class="philadelphia_american"></td>








        <td class="gac__general_agent_center_"></td>








        <td class="health_matching_account_services__hma_"></td>








        <td class="oscar"></td>








        <td class="oneshare_health"></td>








        <td class="molina_"></td>








        <td class="hcsc__bc_bs_of_il__nm__ok__tx_"></td>








        <td class="pivot_health"></td>








        <td class="national_general__tic_"></td>








        <td class="health_matching_account_services"></td>








        <td class="truscript"></td>








        <td class="occidental_life"></td>








        <td class="ambetter"></td>








        <td class="family_life__florida_only_"></td>








        <td class="national_general__nhic_"></td>








        <td class="national_brokerage"></td>








        <td class="mutual_of_omaha"></td>








        <td class="medico"></td>








        <td class="antex___slaico"></td>








        <td class="brokers_alliance"></td>








        <td class="manhattan_life____flic_in_fl_"></td>








        <td class="apex_management_group_mec"></td>








        <td class="united_american"></td>








        <td class="uhc_medicare"></td>








        <td class="assurity_life"></td>








        <td class="cigna"></td>








        <td class="pet_health_matching_account__phma_"></td>








        <td class="national_general__level_funded_small_group_"></td>








        <td class="humana_senior">$ 0.00</td>








        <td class="optimum_ma"></td>








        <td class="ameritas__formerly_security_life_"></td>








        <td class="medi_share_"></td>








        <td class="bc_bs_of_michigan"></td>








        <td class="caresource"></td>








        <td class="ihc__independence_holding_company_"></td>








        <td class="hii__health_insurance_innovations_"></td>








        <td class="gtl__guarantee_trust_life_"></td>








        <td class="aetna_senior"></td>








        <td class="careington"></td>








        <td class="aliera_healthcare"></td>








        <td class="medical_mutual_of_ohio"></td>








        <td class="national_guardian_life__ngl_"></td>








        <td class="av_total">$ 0.00</td>






    </tr>
</tbody>
<tfoot>



    <tr>




        <th class="av_leadsource">&nbsp;</th>








        <th class="national_general__ngah_">$ 1,136,941.49</th>








        <th class="florida_blue___florida_agents_only_">$ 4,233,946.50</th>








        <th class="team_corp">$ 65,063.88</th>








        <th class="vsp">$ 29,670.36</th>








        <th class="united_health_one__golden_rule_">$ 1,547,423.55</th>








        <th class="philadelphia_american">$ 12,460,063.98</th>








        <th class="gac__general_agent_center_">$ 258,199.47</th>








        <th class="health_matching_account_services__hma_">$ 422,297.64</th>








        <th class="oscar">$ 192,732.06</th>








        <th class="oneshare_health">$ 1,226,328.72</th>








        <th class="molina_">$ 228,338.50</th>








        <th class="hcsc__bc_bs_of_il__nm__ok__tx_">$ 149,065.56</th>








        <th class="pivot_health">$ 204,951.39</th>








        <th class="national_general__tic_">$ 60,130.80</th>








        <th class="health_matching_account_services">$ 123,691.32</th>








        <th class="truscript">$ 3,830.88</th>








        <th class="occidental_life">$ 77,083.80</th>








        <th class="ambetter">$ 588,752.01</th>








        <th class="family_life__florida_only_">$ 46,407.96</th>








        <th class="national_general__nhic_">$ 161,834.04</th>








        <th class="national_brokerage">$ 124,887.00</th>








        <th class="mutual_of_omaha">$ 247,790.16</th>








        <th class="medico">$ 5,352.12</th>








        <th class="antex___slaico">$ 58,765.08</th>








        <th class="brokers_alliance">$ 56,217.36</th>








        <th class="manhattan_life____flic_in_fl_">$ 1,475,233.44</th>








        <th class="apex_management_group_mec">$ 55,758.30</th>








        <th class="united_american">$ 67,368.00</th>








        <th class="uhc_medicare">$ 22,518.72</th>








        <th class="assurity_life">$ 5,670.24</th>








        <th class="cigna">$ 112,659.06</th>








        <th class="pet_health_matching_account__phma_">$ 7,656.00</th>








        <th class="national_general__level_funded_small_group_">$ 211,502.16</th>








        <th class="humana_senior">$ 1,828.80</th>








        <th class="optimum_ma">$ 0.00</th>








        <th class="ameritas__formerly_security_life_">$ 10,940.04</th>








        <th class="medi_share_">$ 3,036.00</th>








        <th class="bc_bs_of_michigan">$ 69,082.68</th>








        <th class="caresource">$ 5,864.28</th>








        <th class="ihc__independence_holding_company_">$ 14,706.00</th>








        <th class="hii__health_insurance_innovations_">$ 2,317.84</th>








        <th class="gtl__guarantee_trust_life_">$ 711.00</th>








        <th class="aetna_senior">$ 17,202.48</th>








        <th class="careington">$ 2,494.56</th>








        <th class="aliera_healthcare">$ 6,663.72</th>








        <th class="medical_mutual_of_ohio">$ 9,492.00</th>








        <th class="national_guardian_life__ngl_">$ 7,209.48</th>








        <th class="av_total">$ 25,819,680.43</th>






    </tr>



</tfoot>


</table>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}


export default connect(mapStateToProps)(Table);
