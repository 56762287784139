import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBBreadcrumb, MDBBreadcrumbItem } from "mdbreact";
import {Link} from 'react-router-dom';
import firebase from '../../config/fbConfig';

class CrudView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            book: {},
            key: ''
        }
    }
    componentDidMount() {
        const ref = firebase.firestore().collection('books').doc(this.props.match.params.id);
        ref.get().then((doc) => {
          if (doc.exists) {
            this.setState({
              book: doc.data(),
              key: doc.id,
              isLoading: false
            });
          } else {
            console.log("No such document!");
          }
        });
    }
    render() {
        const {auth} = this.props;
        if(!auth.uid) return  <Redirect to='/' />
        return (
            <Fragment>
                <AdminNav />
                <MDBContainer className="mt-5 pt-5">
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to="/Crud">List</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Viewing</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <MDBRow className="mt-5 mb-5">
                    <MDBCol size="6">
                        <MDBCard style={{ width: "22rem" }} >
                            <MDBCardImage className="img-fluid" src={this.state.book.book_image} waves />
                            <MDBCardBody>
                                <label htmlFor="defaultFormRegisterNameEx" className="grey-text">
                                Book Name : {this.state.book.book_name}
                                </label>
                                <br />
                                <label htmlFor="defaultFormRegisterEmailEx" className="grey-text">
                                Book Author : {this.state.book.book_author}
                                </label>
                                <br />
                                <label htmlFor="defaultFormRegisterEmailEx" className="grey-text">
                                Book Type : {this.state.book.book_type}
                                </label>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                </MDBContainer>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

  export default connect(mapStateToProps)(CrudView);