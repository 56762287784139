import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {signIn} from '../../store/actions/authActions';
import {Redirect} from 'react-router-dom';
import Footer from '../layout/Footer';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBAlert
} from "mdbreact";
import Navbar from "../layout/Navbar";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: ''};
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id] : e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state);
  }

  render() {
    const {authError, auth} = this.props;
    if(auth.uid) return  <Redirect to='/Dashboard' />
    return (
      <Fragment>
        <Navbar />
        <MDBContainer>
          <MDBRow className="mt-5 mb-5">
            <MDBCol md="6" className="offset-md-3">
              <MDBCard>
                <MDBCardBody>
                  <h2 className="text-center">Sign In</h2>
                  <form onSubmit={this.handleSubmit}>
                    <div className="grey-text">
                      <MDBInput
                        label="Type your email"
                        icon="envelope"
                        id="email"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={this.handleChange}
                      />
                      <MDBInput
                        label="Type your password"
                        icon="lock"
                        id="password"
                        type="password"
                        group
                        type="password"
                        onChange={this.handleChange}
                        validate
                      />
                    </div>

                    <div className="text-center mt-4">
                      <MDBBtn color="secondary" className="mb-3" type="submit">
                        Login
                      </MDBBtn>
                    </div>
                      {authError ? <MDBAlert color="danger">{authError}</MDBAlert> : null }
                  </form>
                  <MDBModalFooter>
                    <div className="font-weight-light">
                      <p><Link to='/PassReset'>Forgot Password?</Link></p>
                    </div>
                  </MDBModalFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
