import React, { Component, Fragment } from 'react';
import { MDBView, MDBContainer, MDBInput, MDBBtn, MDBRow, MDBCol  } from 'mdbreact';
import bg from "../../assets/img/sea-min.jpg";
import logo from "../../assets/img/sm-logo-white.png";
import Footer from '../layout/Footer';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import firebase from '../../config/fbConfig';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { name: '', email: '', message: '' };
        this.ref = firebase.firestore().collection('requests');
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
          const { name, email, message} = this.state;
          axios.post(
            'https://us-central1-fir-starter-ea322.cloudfunctions.net/submit'
          );
          this.ref.add({
              name,
              email,
              message
            }).then((docRef) => {
              this.setState({
                name: '',
                email: '',
                message: ''
              });
              toast.success('Request Submitted Successfully!', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });
            })
            .catch((error) => {
              console.error("Error adding document: ", error);
            });
    }



    render() {
        const { name, email, message} = this.state;
        return (
        <Fragment>
            <MDBView src={bg}>
                <div className="float-left">
                    <img src={logo} className="mt-3 ml-5" />
                </div>
                <div className="float-right mr-5 mt-3">
                    <a href="https://www.facebook.com/SeeMonsterDesignCo" target="_blank"><i className="fab fa-facebook white-text fa-2x mr-3"></i></a>
                    <a href="https://www.instagram.com/seemonster_design_co/" target="_blank"><i className="fab fa-instagram white-text fa-2x mr-3"></i></a>
                    <a href="https://www.etsy.com/shop/SeeMonsterDesignCo"><i className="fab fa-etsy white-text fa-2x"></i></a>
                </div>
            </MDBView>
            <MDBContainer>
                <MDBRow className="mt-5 mb-5">
                <ToastContainer />
                    <MDBCol size="6" className="offset-md-3">
                        <h2>Contact Us</h2>
                        <form>
                            <MDBInput label="Name" id="name" value={name} onChange={this.handleChange} />
                            <MDBInput label="Email" id="email" value={email} onChange={this.handleChange} />
                            <MDBInput label="Message" type="textarea" id="message" value={message} onChange={this.handleChange} />
                            <MDBBtn color="secondary" type="submit" onClick={this.handleSubmit}>Submit</MDBBtn>
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <Footer />
        </Fragment> );
    }
}

export default Contact;