import React, { Component } from 'react';
import { Doughnut } from "react-chartjs-2";

class Donut extends Component {
    state = {
        dataDoughnut: {
            labels: ["Red", "Green", "Yellow", "Grey", "Dark Grey"],
            datasets: [
              {
                data: [300, 50, 100, 40, 120],
                backgroundColor: [
                  "#F7464A",
                  "#46BFBD",
                  "#FDB45C",
                  "#949FB1",
                  "#4D5360",
                ],
                hoverBackgroundColor: [
                  "#FF5A5E",
                  "#5AD3D1",
                  "#FFC870",
                  "#A8B3C5",
                  "#616774",
                ],
              },
            ],
          }
    }
    render() {
        return (
            <div className="card card-cascade narrower">
            <div className="view view-cascade gradient-card-header blue">
              <h5 className="mb-0">Doughnut chart</h5>
            </div>
            <div className="card-body card-body-cascade text-center">
              <Doughnut
                data={this.state.dataDoughnut}
                options={{ responsive: true }}
              />
            </div>
          </div>
         );
    }
}

export default Donut;