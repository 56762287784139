import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBAvatar,
  MDBIcon,
  MDBCardBody,
  MDBCardUp,
  MDBCardHeader,
  MDBBtn,
  MDBInput,
  MDBAlert,
  MDBDataTable
} from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import firebase from '../../config/fbConfig';
import { ToastContainer, toast } from 'react-toastify';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {firstName: '', lastName: '', email: '', password: '', title: ''};
    this.ref = firebase.firestore().collection('users');
    this.state = { users: [] }
  }
  onCollectionUpdate = (querySnapshot) => {
    const users = [];
    querySnapshot.forEach((doc) => {
      const { avatar, firstName, lastName, title, email } = doc.data();
      users.push({
        key: doc.id,
        doc, // DocumentSnapshot
        avatar,
        firstName,
        lastName,
        title,
        email
      });
    });
    this.setState({
      users
   });
  }
  componentDidMount() {
    this.ref.onSnapshot(this.onCollectionUpdate);
  }

  deleteUserConfirm = (uid) => {
    if(window.confirm('Do you want to delete the user ?')){
      this.deleteUser(uid)
    }
  }

  deleteUser = async (uid) => {
    var data = JSON.stringify({
     uid : uid,
    });

   const response = await fetch('https://us-central1-fir-starter-ea322.cloudfunctions.net/deleteUser' , {
      method: 'post',
      body:data,
      headers: {
          "Content-type": "application/json; charset=UTF-8",
      }
   });

  const json = await response.json();
  if(json.msg === 'Delete User Successfully') {
    this.ref = firebase.firestore().collection('users');
    toast.success('User Deleted Successfully', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    alert(json.error.message)
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id] : e.target.value
    })
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    var data = JSON.stringify({
     email : this.state.email,
     firstName : this.state.firstName,
     lastName : this.state.lastName,
     password : this.state.password,
     title : this.state.title,
    });

   const response = await fetch('https://us-central1-fir-starter-ea322.cloudfunctions.net/createUser' , {
      method: 'post',
      body:data,
      headers: {
          "Content-type": "application/json; charset=UTF-8",
      }
   });

  const json = await response.json();
  if(json.msg === 'User created Successfully') {
    this.setState({
      firstName: '', lastName: '', email: '', password: '', title: ''
    });
    toast.success('User Added successfully!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  } else {
   alert(json.error.message)
  }
  }

  render() {
    const {auth, authError} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
    const users = this.state.users;
    const data = {
        columns: [
          {
            label: "First Name",
            field: "fname",
            sort: "asc",
            width: 150,
          },
          {
            label: "Last Name",
            field: "lname",
            sort: "asc",
            width: 150,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 150,
          },
          {
            label: "Title",
            field: "title",
            sort: "asc",
            width: 150,
          },
          {
            label: "Avatar",
            field: "avatar",
            sort: "asc",
            width: 150,
          },
          {
            label: "Delete",
            field: "delete",
            sort: "asc",
            width: 150,
          },

        ],
        rows : users.map(user => {
            return {
                fname: user.firstName,
                lname: user.lastName,
                email: user.email,
                title: user.title,
                avatar: (
                  <img src={user.avatar} className="img-fluid rounded-circle" style={{height: "80px"}} />
                ),
                delete: (
                    <MDBIcon icon="trash" size="2x" className="d-flex justify-content-center red-text" onClick={() => this.deleteUserConfirm(user.doc.id)}  />
                ),


            }
        })
      };

    return (
      <Fragment>
        <AdminNav />
        <MDBContainer className="mt-5 pt-5">
          <MDBRow className="mt-5 mb-5">
          <ToastContainer />
            <MDBCol md="8">
                <h3>Users List:</h3>
                <MDBDataTable striped bordered small entries={50} entriesOptions={[50, 100, 150]} order={['name', 'desc' ]} data={data} />
            </MDBCol>
            <MDBCol md="4" >
              <MDBCard>
                <MDBCardBody>
                  <MDBCardHeader className="form-header purple-gradient rounded">
                    <h3 className="my-3">Create User:</h3>
                  </MDBCardHeader>
                  <form onSubmit={this.handleSubmit}>
                    <div className="grey-text">
                      <MDBInput
                        label="First Name"
                        icon="user"
                        id="firstName"
                        onChange={this.handleChange}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                      />
                      <MDBInput
                        label="Last Name"
                        icon="user"
                        id="lastName"
                        onChange={this.handleChange}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                      />
                      <MDBInput
                        label="Title"
                        icon="briefcase"
                        id="title"
                        onChange={this.handleChange}
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                      />


                      <MDBInput
                        label="Email"
                        icon="envelope"
                        id="email"
                        onChange={this.handleChange}
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                      />


                      <MDBInput
                        label="Password"
                        id="password"
                        onChange={this.handleChange}
                        icon="lock"
                        group
                        type="password"
                        validate
                      />
                    </div>

                    <div className="text-center mt-4">
                      <MDBBtn color="light-blue" className="mb-3" type="submit">
                        Create User
                      </MDBBtn>
                      {authError ? <MDBAlert color="danger">{authError}</MDBAlert> : null }
                    </div>
                  </form>

                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    );
  }
}


const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      authError: state.auth.authError
    }
  }



  export default connect(mapStateToProps)(Users);
