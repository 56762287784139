import React, { Component, Fragment } from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import BarChart from './BarChart';
import PieChart from './PieChart';
import Donut from './Donut';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {compose} from 'redux';
import {Redirect} from 'react-router-dom';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    //console.log(this.props);
    const {projects, auth} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
    return (
      <Fragment>
        <AdminNav />
        <main className="mt-5 pt-5">
          <MDBContainer fluid className="mt-5 ml-2">
            <MDBRow>
              <MDBCol md="3" className="mb-4">
                <div className="card">
                  <div className="row mt-3">
                    <div className="col-md-5 col-5 text-left pl-4">
                      <a
                        type="button"
                        className="btn-floating btn-lg primary-color ml-4"
                      >
                        <i className="fas fa-school" aria-hidden="true"></i>
                      </a>
                    </div>

                    <div className="col-md-7 col-7 text-right pr-5">
                      <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                        4,567{" "}
                      </h5>

                      <p className="font-small grey-text">Total</p>
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="3" className="mb-4">
                <div className="card">
                  <div className="row mt-3">
                    <div className="col-md-5 col-5 text-left pl-4">
                      <a
                        type="button"
                        className="btn-floating btn-lg danger-color ml-4"
                      >
                        <i className="fas fa-scroll" aria-hidden="true"></i>
                      </a>
                    </div>

                    <div className="col-md-7 col-7 text-right pr-5">
                      <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                        4,567{" "}
                      </h5>

                      <p className="font-small grey-text">2020</p>
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="3" className="mb-4">
                <div className="card">
                  <div className="row mt-3">
                    <div className="col-md-5 col-5 text-left pl-4">
                      <a
                        type="button"
                        className="btn-floating btn-lg warning-color ml-4"
                      >
                        <i className="fas fa-suitcase" aria-hidden="true"></i>
                      </a>
                    </div>

                    <div className="col-md-7 col-7 text-right pr-5">
                      <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                        4,567{" "}
                      </h5>

                      <p className="font-small grey-text">Total</p>
                    </div>
                  </div>
                </div>
              </MDBCol>
              <MDBCol md="3" className="mb-4">
                <div className="card">
                  <div className="row mt-3">
                    <div className="col-md-5 col-5 text-left pl-4">
                      <a
                        type="button"
                        className="btn-floating btn-lg success-color ml-4"
                      >
                        <i
                          className="fas fa-dollar-sign"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>

                    <div className="col-md-7 col-7 text-right pr-5">
                      <h5 className="ml-4 mt-4 mb-2 font-weight-bold">
                        4,567{" "}
                      </h5>

                      <p className="font-small grey-text">$ Received</p>
                    </div>
                  </div>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="4">
                <BarChart />
              </MDBCol>
              <MDBCol md="4">
                <PieChart />
              </MDBCol>
              <MDBCol md="4">
                <Donut />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    {collection: 'projects'}
  ])
)(Dashboard);
