import React, { Component, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { MDBRow, MDBDataTable, MDBCol, MDBIcon} from "mdbreact";
import {Link} from 'react-router-dom';
import firebase from '../../config/fbConfig';


class List extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection('books');
        this.state = { books: [] }
    }
    onCollectionUpdate = (querySnapshot) => {
      const books = [];
      querySnapshot.forEach((doc) => {
        const { book_name, book_author, book_type, book_image } = doc.data();
        books.push({
          key: doc.id,
          doc, // DocumentSnapshot
          book_name,
          book_author,
          book_type,
          book_image
        });
      });
      this.setState({
        books
     });
    }

    componentDidMount() {
      this.ref.onSnapshot(this.onCollectionUpdate);
    }

    delete(id){
      firebase.firestore().collection('books').doc(id).delete().then(() => {
        toast.success('Book Deleted successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log("Document successfully deleted!");
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    }

    render() {
        const books = this.state.books;
        const data = {
            columns: [
              {
                label: "Book",
                field: "book_name",
                sort: "asc",
                width: 150,
              },
              {
                label: "Author",
                field: "book_author",
                sort: "asc",
                width: 150,
              },
              {
                label: "Book Type",
                field: "book_type",
                sort: "asc",
                width: 150,
              },
              {
              label: "Update",
              field: "update",
              sort: "asc",
              width: 150,
              },
              {
                label: "Delete",
                field: "delete",
                sort: "asc",
                width: 150,
              },

            ],
            //rows: books
            rows : books.map(book => {
                return {
                    book_name: (
                      <Link to={`/CrudView/${book.key}`}>{book.book_name}</Link>
                    ),
                    book_author: book.book_author,
                    book_type: book.book_type,
                      update: (
                        <Link to={`/EditCrud/${book.key}`}>
                          <MDBIcon icon="user-edit" size="2x" className="d-flex justify-content-center blue-text" />
                        </Link>
                      ),

                    delete: (
                        <MDBIcon icon="trash" size="2x" className="d-flex justify-content-center red-text" onClick={this.delete.bind(this, book.key)}  />
                    ),


                }
            })
          };

        return (
        <Fragment>
            <MDBRow className="mt-5 mb-5">
              <ToastContainer />
                <MDBCol size="12">
                    <MDBDataTable striped bordered small data={data} exportToCSV />
                </MDBCol>
            </MDBRow>
        </Fragment>  );
    }
}

export default List;