import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import { MDBContainer, MDBBtn, MDBIcon } from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import {Link} from 'react-router-dom';
import List from './List';

class Crud extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    render() {
        const {auth} = this.props;
        if(!auth.uid) return  <Redirect to='/' />

        return (
        <Fragment>
            <AdminNav />
            <MDBContainer className="mt-5 pt-5">
            <h2>
              Crud
                <Link to="/AddCrud"><MDBBtn tag="a" floating gradient="purple">
                  <MDBIcon icon="plus" />
                </MDBBtn></Link>
              </h2>
              <List />
            </MDBContainer>
        </Fragment>);
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

export default connect(mapStateToProps)(Crud);