const initState = {authError: null};

const passReducer = (state = initState, action) => {
    switch(action.type){
        case 'PASS_SUCCESS':
            console.log('password reset');
            return{
                ...state,
                authError: null
            }
        case 'PASS_ERROR':
            console.log('pass error')
            return {
                ...state,
                authError: 'Email does not exist'
            }
        default: return state;
    }
}

export default passReducer;