import React from 'react';
import firebase from '../../config/fbConfig';
import { ToastContainer, toast } from 'react-toastify';

function Avatar() {

    const [file, fileInfo] = React.useState(null);

    const onFileChange = async (e) => {
      fileInfo(e.target.files[0]);
    };

    const onSubmit = async (e) => {
      e.preventDefault();
      document.getElementById("submit").disabled = true;
      const uid = firebase.auth().currentUser.uid;
      const storageRef = firebase.storage().ref(`avatars/`);
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      const fileUrl = await fileRef.getDownloadURL();
      if (!fileUrl) {
        return;
      }
      await firebase.firestore().collection('users').doc(uid).update({
        avatar: fileUrl
      }).then(() => {
        document.getElementById("submit").disabled = false;
        toast.success('Avatar Updated successfully!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
      })
      .catch((error) => {
        document.getElementById("submit").disabled = false;
        console.error("Error adding document: ", error);
      });
    };

    return (
      <>
      <ToastContainer />
      <form onSubmit={onSubmit}>
        <input type="file" onChange={onFileChange} />
        <button id="submit" className="btn btn-purple">Submit</button>
      </form>
      </>
    );
  }

  export default Avatar;