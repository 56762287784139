import React, { Component } from 'react';
import { Bar} from "react-chartjs-2";

class BarChart extends Component {
    state = {
        dataBar: {
            labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
            datasets: [
              {
                label: "% of Votes",
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                  "rgba(255, 134,159,0.4)",
                  "rgba(98,  182, 239,0.4)",
                  "rgba(255, 218, 128,0.4)",
                  "rgba(113, 205, 205,0.4)",
                  "rgba(170, 128, 252,0.4)",
                  "rgba(255, 177, 101,0.4)",
                ],
                borderWidth: 2,
                borderColor: [
                  "rgba(255, 134, 159, 1)",
                  "rgba(98,  182, 239, 1)",
                  "rgba(255, 218, 128, 1)",
                  "rgba(113, 205, 205, 1)",
                  "rgba(170, 128, 252, 1)",
                  "rgba(255, 177, 101, 1)",
                ],
              },
            ],
          },
          barChartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  barPercentage: 1,
                  gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    display: true,
                    color: "rgba(0, 0, 0, 0.1)",
                  },
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }
    }
    render() {
        return (
            <div className="card card-cascade narrower">
            <div className="view view-cascade gradient-card-header blue">
              <h5 className="mb-0">Bar chart</h5>
            </div>
            <div className="card-body card-body-cascade text-center">
              <Bar
                data={this.state.dataBar}
                options={this.state.barChartOptions}
              />
            </div>
          </div>
          );
    }
}

export default BarChart;