import React, { Component, Fragment } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
} from "mdbreact";
import logo from "../../assets/img/sm-logo-white.png";
import {connect} from 'react-redux';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  render() {
    return (
      <div>
        <header>
          <MDBNavbar color="secondary-color" expand="md" className="sinclairRed">
            <MDBContainer>
              <MDBNavbarBrand href="/">
                <img src={logo} />
              </MDBNavbarBrand>
              <MDBNavbarToggler onClick={this.onClick} />
              <MDBCollapse isOpen={this.state.collapse} navbar>
                <MDBNavbarNav right>
                  <MDBNavItem>
                    <a href="https://www.facebook.com/SeeMonsterDesignCo" target="_blank"><i className="fab fa-facebook white-text fa-2x mr-3"></i></a>
                  </MDBNavItem>
                  <MDBNavItem>
                    <a href="https://www.instagram.com/seemonster_design_co/" target="_blank"><i className="fab fa-instagram white-text fa-2x mr-3"></i></a>
                  </MDBNavItem>
                  <MDBNavItem>
                    <a href="https://www.etsy.com/shop/SeeMonsterDesignCo"><i className="fab fa-etsy white-text fa-2x"></i></a>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbar>
        </header>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log(state);
  return{

  }
}

export default connect(mapStateToProps)(Navbar);
