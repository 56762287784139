export const passReset = (credentials) => {
    return(dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().sendPasswordResetEmail(
            credentials.email
        ).then(() => {
            dispatch({type: 'PASS_SUCCESS'});
        }).catch((err) => {
            console.log(err);
            dispatch({type: 'PASS_ERROR', err});
        })
    }
}