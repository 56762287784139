import React, { Component } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import firebase from '../../config/fbConfig';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {},
      key: ''
    };
  }
  componentDidMount() {
    const ref = firebase.firestore().collection('pages').doc('TwxBawWcbeYeKpYa0Iyt');
    ref.get().then((doc) => {
      if (doc.exists) {
        this.setState({
          page: doc.data(),
          key: doc.id,
          isLoading: false
        });
      } else {
        console.log("No such document!");
      }
    });
  }

  render() {
    return (
      <MDBFooter color="stylish-color-dark" className="font-small pt-4 mt-4">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="12" className="text-center mt-5 mb-5">
            <div dangerouslySetInnerHTML={{ __html: this.state.page.content }}></div>
          </MDBCol>
          <MDBCol size="12" className="text-center mt-5 mb-5">
            <a href="https://www.facebook.com/SeeMonsterDesignCo" target="_blank"><i className="fab fa-facebook white-text fa-2x mr-3"></i></a>
            <a href="https://www.instagram.com/seemonster_design_co/" target="_blank"><i className="fab fa-instagram white-text fa-2x mr-3"></i></a>
            <a href="https://www.etsy.com/shop/SeeMonsterDesignCo"><i className="fab fa-etsy white-text fa-2x"></i></a>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: SeeMonster Design Co.
        </MDBContainer>
      </div>
    </MDBFooter>
    );
  }
}

export default Footer;
