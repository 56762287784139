import React, { Component, Fragment} from 'react';
import { MDBView, MDBContainer } from 'mdbreact';
import bg from "../../assets/img/sea-min.jpg";
import logo from "../../assets/img/sm-logo-white.png";
import Products from './products';
import Footer from '../layout/Footer';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        return (
        <Fragment>
            <MDBView src={bg}>
                <div className="float-left">
                    <img src={logo} className="mt-3 ml-5" />
                </div>
                <div className="float-right mr-5 mt-3">
                    <a href="https://www.facebook.com/SeeMonsterDesignCo" target="_blank"><i className="fab fa-facebook white-text fa-2x mr-3"></i></a>
                    <a href="https://www.instagram.com/seemonster_design_co/" target="_blank"><i className="fab fa-instagram white-text fa-2x mr-3"></i></a>
                    <a href="https://www.etsy.com/shop/SeeMonsterDesignCo"><i className="fab fa-etsy white-text fa-2x"></i></a>
                </div>
            </MDBView>
            <MDBContainer>
                <Products />
            </MDBContainer>
            <Footer />
        </Fragment>  );
    }
}

export default Home;