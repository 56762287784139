import React, { Component, Fragment } from 'react';
import { MDBRow,
    MDBCol,MDBBtn} from 'mdbreact';
import prod1 from "../../assets/img/prod1.jpg";
import prod2 from "../../assets/img/prod2.jpg";
import prod3 from "../../assets/img/prod3.jpg";
import prod4 from "../../assets/img/prod4.jpg";
import prod5 from "../../assets/img/prod5.jpg";
import prod6 from "../../assets/img/prod6.jpg";
import prod7 from "../../assets/img/prod7.jpg";
import prod8 from "../../assets/img/prod8.jpg";
import prod9 from "../../assets/img/prod9.jpg";
import prod10 from "../../assets/img/prod10.jpg";
import prod11 from "../../assets/img/prod11.jpg";
import prod12 from "../../assets/img/prod12.jpg";
import prod13 from "../../assets/img/prod13.jpg";

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {   }
    }

    render() {
        return (
        <Fragment>
            <div className="mt-5 mb-5">
                <h2 className="text-center">Our Products</h2>
                <hr />
                <MDBRow>
                    <MDBCol lg="4" md="12" className="mb-3">
                    <img src={prod1} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod2} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod13} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md="4" className="mb-3">
                        <img src={prod12} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol md="4" className="mb-3">
                        <img src={prod3} className="img-fluid z-depth-1" alt="" / >
                    </MDBCol>
                    <MDBCol lg="4" md="12" className="mb-3">
                    <img src={prod4} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod11} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod5} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol md="4" className="mb-3">
                        <img src={prod10} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol lg="4" md="12" className="mb-3">
                    <img src={prod6} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod7} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="mb-3">
                    <img src={prod8} className="img-fluid z-depth-1" alt="" />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="12">
                        <MDBBtn
                            href="https://www.etsy.com/shop/SeeMonsterDesignCo"
                            target="_blank"
                            color="secondary"
                            className="btn-lg btn-block"
                        >View All Our Products
                        </MDBBtn>
                    </MDBCol>
                </MDBRow>

            </div>
        </Fragment> );
    }
}

export default Products;