import React from 'react';
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';
import {
    MDBNavItem,
    MDBIcon,
  } from "mdbreact";

const SignOut = (props) => {
    return(
        <MDBNavItem className="pt-2 pl-2">
        <a onClick={props.signOut}>
          <MDBIcon icon="sign-out-alt" className="d-inline-inline pr-2" />
        </a>
      </MDBNavItem>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(null, mapDispatchToProps)(SignOut);