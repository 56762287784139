import React, { Component, Fragment } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBGallery, MDBGalleryList
  } from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import firebase from '../../config/fbConfig';
import GalUpload from './GalUpload';


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection('gallery');
        this.state = {
          key: '',
          image: '',
          images: []
      };
    }
    onCollectionUpdate = (querySnapshot) => {
        const images = [];
        querySnapshot.forEach((doc) => {
          const { image } = doc.data();
          images.push({
            key: doc.id,
            doc, // DocumentSnapshot
            image
          });
        });
        this.setState({
          images
       });
      }
      componentDidMount() {
        this.ref.onSnapshot(this.onCollectionUpdate);
      }


    render() {
        const {auth} = this.props;
        if(!auth.uid) return  <Redirect to='/' />
        const images = this.state.images;
        return (
        <Fragment>
            <AdminNav />
            <MDBContainer className="mt-5 pt-5">
                <MDBRow>
                    <MDBCol size="12">
                        <h2>Upload Images</h2>
                        <GalUpload />
                    </MDBCol>
                    <MDBCol size="12" className="mt-5">
                        <h2>Gallery</h2>
                        <MDBGallery cols={3}>
                            {images.map((i) => {
                                return (
                                <MDBGalleryList key={i} rows={2}>
                                    <img src={i.image}  />
                                </MDBGalleryList>
                                );
                            })}
                        </MDBGallery>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Fragment> );
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

export default connect(mapStateToProps)(Gallery);