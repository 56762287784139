import React, { Component} from "react";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {signOut} from '../../store/actions/authActions';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
  MDBSideNavItem,
  MDBSideNavNav,
  MDBSideNav,
} from "mdbreact";
import Logo from "../../assets/img/kraken-icon.png";
import SignOut from './signOut';

class AdminNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
    };
  }

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA,
    });
  };
  render() {
    const specialCaseNavbarStyles = {
      WebkitBoxOrient: "horizontal",
      flexDirection: "row",
    };
    const {profile} = this.props;

    return (
      <div>
        <MDBSideNav
          triggerOpening={this.state.toggleStateA}
          mask="strong"
          hidden
        >
          <img
            className="img-fluid mx-auto d-block adminLogo mt-2"
            alt="SeeMonster Design Co."
            src={Logo}
            style={{ height: "100px" }}
          />
          <MDBSideNavNav>
            <MDBSideNavItem>
              <Link to="/Dashboard">
                <MDBIcon fas icon="tachometer-alt" className="mr-3" />
                Dashboard
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/Crud">
                <MDBIcon fas icon="tasks" className="mr-3" />
                Crud
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/Gallery">
                <MDBIcon fas icon="camera-retro" className="mr-3" />
                Gallery
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/Events">
                <MDBIcon fas icon="calendar-alt" className="mr-3" />
                Events
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/AdminPages">
                <MDBIcon fas icon="pen-fancy" className="mr-3" />
                Pages
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/Requests">
                <MDBIcon fas icon="paper-plane" className="mr-3" />
                Requests
              </Link>
            </MDBSideNavItem>
            <MDBSideNavItem>
              <Link to="/Users">
                <MDBIcon fas icon="users" className="mr-3" />
                Users
              </Link>
            </MDBSideNavItem>
          </MDBSideNavNav>
        </MDBSideNav>
        <MDBNavbar double expand="md" fixed="top" scrolling className="kraken">
          <MDBNavbarNav left>
            <MDBNavItem>
              <div
                onClick={this.handleToggleClickA}
                key="sideNavToggleA"
                style={{
                  lineHeight: "32px",
                  marginRight: "1em",
                  verticalAlign: "middle",
                }}
              >
                <MDBIcon icon="bars" color="white" size="2x" />
              </div>
            </MDBNavItem>
            <MDBNavItem
              className="d-none d-md-inline"
              style={{ paddingTop: 5 }}
            >
              SeeMonster Design Co.
            </MDBNavItem>
          </MDBNavbarNav>
          <MDBNavbarNav right style={specialCaseNavbarStyles}>
            <MDBNavItem>
              <MDBNavLink to="/Support">
                <MDBIcon far icon="comments" className="d-inline-inline" />{" "}
                <div className="d-none d-md-inline">Support</div>
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="/Account">
                <img src={profile.avatar} height="30" alt="Account" className="rounded-circle ml-3 mr-3" />
              </MDBNavLink>
            </MDBNavItem>
            <SignOut />
          </MDBNavbarNav>
        </MDBNavbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNav);
