import React, { Component, Fragment } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBBtn,
    MDBInput,
  } from "mdbreact";
  import AdminNav from "../layout/AdminNavBar";
  import {connect} from 'react-redux';
  import {Redirect} from 'react-router-dom';

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        const {auth} = this.props;
        if(!auth.uid) return  <Redirect to='/' />
        return ( 
        <Fragment>
            <AdminNav />
            <MDBContainer className="mt-5 pt-5">
            </MDBContainer>

                </Fragment> );
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

  export default connect(mapStateToProps)(Templates);