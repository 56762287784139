const initState = {
    projects: [
    {id: '1', title: 'First', content: 'this is first'},
    {id: 2, title: 'Second', content: 'this is second'},
    {id: 3, title: 'Third', content: 'this is third'}
]};


const projectReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_PROJECT':
            console.log('created project', action.project);
            return state;
        case 'CREATE_PROJECT_ERROR':
            console.log('create project error', action.err)
            return state;
        default: return state;
    }
}

export default projectReducer;