import React, { Component } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";
import SignIn from "../auth/SignIn";
import PassReset from '../auth/PassReset';
import Dashboard from "../dashboard/Dashboard";
import Users from '../users/Users';
import Templates from '../templates/templates';
import Account from '../account/Account';
import Crud from '../crud/Crud';
import CrudView from '../crud/CrudView';
import AddCrud from '../crud/AddCrud';
import EditCrud from '../crud/Edit';
import Home from '../home/home';
import AdminPages from '../AdminPages/list';
import AdminEditPages from '../AdminPages/edit';
import Gallery from '../gallery/Gallery';
import Events from '../events/Events';
import Requests from '../requests/Requests';
import Table from '../table/table';
import Contact from '../home/contact';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { token: '' }
  }

  render() {
    return (
        <Router>
          <Route path="/" component={Home} exact />
          <Route path="/login" component={SignIn} exact />
          <Route path="/PassReset" component={PassReset} exact />
          <Route path="/Dashboard" component={Dashboard} exact />
          <Route path="/Users" component={Users} exact />
          <Route path="/Templates" component={Templates} exact />
          <Route path="/Account" component={Account} exact />
          <Route path="/Crud" component={Crud} exact />
          <Route path="/CrudView/:id" component={CrudView} exact />
          <Route path="/AddCrud" component={AddCrud} exact />
          <Route path="/EditCrud/:id" component={EditCrud} exact />
          <Route path="/AdminPages" component={AdminPages} exact />
          <Route path="/EditPage/:id" component={AdminEditPages} exact />
          <Route path="/Gallery" component={Gallery} exact />
          <Route path="/Events" component={Events} exact />
          <Route path="/Requests" component={Requests} exact />
          <Route path="/Table" component={Table} exact />
          <Route path="/Contact" component={Contact} exact />
        </Router>
        );
  }
}


export default App;
