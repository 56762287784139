import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import {  MDBRow, MDBCol, MDBCard, MDBAvatar, MDBCardBody, MDBContainer, MDBFileInput, MDBCardUp, MDBInput, MDBBtn } from "mdbreact";
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import firebase from '../../config/fbConfig';
import { ToastContainer, toast } from 'react-toastify';
import Avatar from './Avatar';

class Account extends Component {
  constructor(props) {
    super(props);
    this.ref = firebase.firestore().collection('users');
    this.state = {
      key: '',
      firstName: '',
      lastName: '',
      title: '',
      email: '',
      avatar: ''
  };
  }

  componentDidMount() {
    const uid = firebase.auth().currentUser.uid;
    const ref = firebase.firestore().collection('users').doc(uid);
    ref.get().then((doc) => {
      if (doc.exists) {
        const user = doc.data();
        this.setState({
          key: doc.id,
          firstName: user.firstName,
          lastName: user.lastName,
          title: user.title,
          email: user.email,
          avatar: user.avatar
        });
      } else {
        console.log("No such document!");
      }
    });
  }

  handleChange = (e) => {
    this.setState({
        [e.target.id] : e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const uid = firebase.auth().currentUser.uid;
    const { firstName, lastName, title, email} = this.state;

    const updateRef = firebase.firestore().collection('users').doc(uid);
    updateRef.update({
      firstName,
      lastName,
      title,
      email
    }).then((docRef) => {
      this.setState({
        firstName: firstName,
        lastName: lastName,
        title: title,
        email: email
      });
      toast.success('Profile Updated successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
  }




  render() {
    const {auth, profile} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
    return (
      <Fragment>
        <AdminNav />
        <MDBContainer className="mt-5 pt-5">
          <MDBRow className="mt-5 mb-5">
            <MDBCol md='6'>
              <MDBCard testimonial>
                <MDBCardUp className='purple lighten-1' />
                <MDBAvatar className='mx-auto white'>
                  <img
                    src={profile.avatar}
                    alt=''
                  />
                </MDBAvatar>
                <MDBCardBody>
                  <h4 className='card-title'>{profile.firstName} {profile.lastName}</h4>
                  <hr />
                  <h5>{profile.title}</h5>
                  <h5>{profile.email}</h5>
                  <hr />
                  <Avatar />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol md="6" >
              <ToastContainer />
              <h2>Edit Profile</h2>
              <form>
                  <MDBInput id="firstName" label="First Name" value={this.state.firstName} name="firstName" onChange={this.handleChange} />
                  <MDBInput id="lastName" label="Last Name" value={this.state.lastName} name="lastName" onChange={this.handleChange} />
                  <MDBInput id="title" label="Title" value={this.state.title} name="title" onChange={this.handleChange} />
                  <MDBInput id="email" label="email" value={this.state.email} name="email" onChange={this.handleChange} />
                  <div className="text-center mt-4">
                  <MDBBtn color="purple" type="submit" onClick={this.handleSubmit}>
                      Save
                  </MDBBtn>
                  </div>
              </form>

            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}


export default connect(mapStateToProps)(Account);
