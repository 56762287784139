import React, { Component, Fragment } from 'react';
import firebase from '../../config/fbConfig';
import {Link} from 'react-router-dom';
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBBreadcrumb, MDBBreadcrumbItem, MDBInput, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import {connect} from 'react-redux';
import AdminNav from "../layout/AdminNavBar";
import {Redirect} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

class AddCrud extends Component {
    constructor(props) {
        super(props);
        this.ref = firebase.firestore().collection('books');
        this.state = {
            book_name: '',
            book_author: '',
            book_image: '',
            book_type: ''
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id] : e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const { book_name, book_author, book_image, book_type} = this.state;
        this.ref.add({
            book_name,
            book_author,
            book_image,
            book_type
          }).then((docRef) => {
            this.setState({
              book_name: '',
              book_author: '',
              book_image: '',
              book_type: ''
            });
            toast.success('Book Added successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
          });
    }

    render() {
    const {auth} = this.props;
    if(!auth.uid) return  <Redirect to='/' />
    const { book_name, book_author, book_image, book_type} = this.state;
    const options = [
        {
            label: 'Horror',
            value: 'Horror',
        },
        {
            label: 'Fantasy',
            value: 'Fantasy',
        },
        {
            label: 'Crime',
            value: 'Crime',
        }
    ]
        return (
            <Fragment>
                <AdminNav />
                <MDBContainer className="mt-5 pt-5">
                <MDBBreadcrumb>
                    <MDBBreadcrumbItem><Link to="/Crud">List</Link></MDBBreadcrumbItem>
                    <MDBBreadcrumbItem active>Add Book</MDBBreadcrumbItem>
                </MDBBreadcrumb>
                <h2>Add Book</h2>
                    <MDBRow>
                        <MDBCol size="4">
                        <ToastContainer />
                            <form>
                                <MDBInput id="book_name" label="Book Name" value={book_name} onChange={this.handleChange} />
                                <MDBInput id="book_author" label="Book Author" value={book_author} name="book_author" onChange={this.handleChange} />

                                <select className="form-control" value={book_type} onChange={this.handleChange} id="book_type">
                                    <option>-- Select Type --</option>
                                    {options.map((option) => (
                                    <option value={option.value}>{option.label}</option>
                                    ))}
                                </select>

                                <MDBInput id="book_image" label="Book Image" value={book_image} name="book_image" onChange={this.handleChange} />
                                <div className="text-center mt-4">
                                <MDBBtn color="unique" type="submit" onClick={this.handleSubmit}>
                                    Save
                                </MDBBtn>
                                </div>
                            </form>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </Fragment>
         );
    }
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth
    }
  }

export default connect(mapStateToProps)(AddCrud);